<template>
  <div class="mb10">
    <h2 class="content_title">
      {{ $t('msg.SETT010T010.112') }} <!-- 화주 구분 -->
    </h2>
    <div>
      <!-- 월말화주(M), 청구화주(C), 영수화주(R) -->
      <template v-if="dpId !== 'CREDIT'">
        <input type="radio" id="dmd-r" value="R" v-model="selectDmdCatCd"><label for="dmd-r">{{ $t('msg.SETT010T010.113') }}</label>
      </template>
      <template v-if="dpId !== 'CREDIT' && (oriDmdCatCd === 'M' || oriDmdCatCd === 'C')">
        <input type="radio" id="dmd-c" value="C" v-model="selectDmdCatCd"><label for="dmd-c">{{ $t('msg.SETT010T010.198') }}</label>
      </template>
      <template v-if="dpId === 'CREDIT' || (dpId !== 'TAX_INVOICE' && oriDmdCatCd === 'M')">
        <input type="radio" id="dmd-m" value="M" v-model="selectDmdCatCd"><label for="dmd-m">{{ $t('msg.SETT010T010.199') }}</label>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FreightBillPoint',
  props: ['dmdCatCd', 'oriDmdCatCd', 'dpId'],
  data: function () {
    return {
      selectDmdCatCd: null,
      isInit: false
    }
  },
  computed: {

  },
  watch: {
    dmdCatCd: function () {
      this.selectDmdCatCd = this.dmdCatCd
    },
    selectDmdCatCd: function () {
      if (this.isInit) {
        this.$emit('child', { type: 'updateSelectDmdCatCd', payload: { selectDmdCatCd: this.selectDmdCatCd } })
      }
      this.isInit = true
    }
  },
  created () {},
  mounted () {
    if (this.dpId === 'CREDIT') {
      this.selectDmdCatCd = 'M'
    } else {
      this.selectDmdCatCd = this.dmdCatCd
    }
  },
  methods: {
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    }
  }
}
</script>
<style>

</style>
