var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb10" }, [
    _c("h2", { staticClass: "content_title" }, [
      _vm._v(" " + _vm._s(_vm.$t("msg.SETT010T010.112")) + " "),
    ]),
    _c(
      "div",
      [
        _vm.dpId !== "CREDIT"
          ? [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectDmdCatCd,
                    expression: "selectDmdCatCd",
                  },
                ],
                attrs: { type: "radio", id: "dmd-r", value: "R" },
                domProps: { checked: _vm._q(_vm.selectDmdCatCd, "R") },
                on: {
                  change: function ($event) {
                    _vm.selectDmdCatCd = "R"
                  },
                },
              }),
              _c("label", { attrs: { for: "dmd-r" } }, [
                _vm._v(_vm._s(_vm.$t("msg.SETT010T010.113"))),
              ]),
            ]
          : _vm._e(),
        _vm.dpId !== "CREDIT" &&
        (_vm.oriDmdCatCd === "M" || _vm.oriDmdCatCd === "C")
          ? [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectDmdCatCd,
                    expression: "selectDmdCatCd",
                  },
                ],
                attrs: { type: "radio", id: "dmd-c", value: "C" },
                domProps: { checked: _vm._q(_vm.selectDmdCatCd, "C") },
                on: {
                  change: function ($event) {
                    _vm.selectDmdCatCd = "C"
                  },
                },
              }),
              _c("label", { attrs: { for: "dmd-c" } }, [
                _vm._v(_vm._s(_vm.$t("msg.SETT010T010.198"))),
              ]),
            ]
          : _vm._e(),
        _vm.dpId === "CREDIT" ||
        (_vm.dpId !== "TAX_INVOICE" && _vm.oriDmdCatCd === "M")
          ? [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectDmdCatCd,
                    expression: "selectDmdCatCd",
                  },
                ],
                attrs: { type: "radio", id: "dmd-m", value: "M" },
                domProps: { checked: _vm._q(_vm.selectDmdCatCd, "M") },
                on: {
                  change: function ($event) {
                    _vm.selectDmdCatCd = "M"
                  },
                },
              }),
              _c("label", { attrs: { for: "dmd-m" } }, [
                _vm._v(_vm._s(_vm.$t("msg.SETT010T010.199"))),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }